import { useState, useEffect } from 'react';
import { useRequestContext } from '../hooks';
import {
  GlobalStateType,
  GridItemProps,
  ComposerGroupItem,
  Config,
  Table,
  VisualProfileColors,
  RootComposerGroup,
  Font,
  PaymentProvider,
  Language
} from '../types';
import i18n from '../locales/i18n';
import { getAllComposerTiles } from '../services/composer.service';
import { getShop } from '../services/shops.service';
import {
  getGlobalPayConfig,
  getValitorConfig
} from '../services/payment.service';
import { setNamedLocalStorage } from '../utils/namedLocalStorage';

const DEFAULT_LOCALE = 'en-gb';

const mapGridItems = (composerTiles: ComposerGroupItem[] = []) =>
  composerTiles.map(
    ({
      displayName,
      priceRange,
      groupUrlName,
      isCustomizable,
      ...rest
    }: ComposerGroupItem) => ({
      ...rest,
      label: displayName,
      price: priceRange ? priceRange.from : 0,
      value: {
        groupUrlName: groupUrlName || '',
        isCustomizable
      }
    })
  );

const useGlobalState = (): GlobalStateType => {
  const [composerTiles, setComposerTiles] = useState<GridItemProps[]>([]);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [currencyCode, setCurrencyCode] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<Config>();
  const [table, setTable] = useState<Table>({
    id: 0,
    name: '',
    description: ''
  });
  const [visualProfileColors, setVisualProfile] = useState<
    VisualProfileColors
  >();
  const [visualProfileFonts, setVisualProfileFonts] = useState<Font>();
  const [rootComposerGroup, setRootComposerGroup] = useState<
    RootComposerGroup
  >();
  const [paymentProvider, setPaymentProvider] = useState<PaymentProvider>();
  const [defaultLocale, setDefaultLocale] = useState<string>(DEFAULT_LOCALE);
  const [shopName, setShopName] = useState<string>();
  const { tenantId, shopId } = useRequestContext();

  const getComposerTiles = async () => {
    // effect with isFetching seeming due to race with states being set. positioned setLoading to prevent duplicate network calls
    const isLoading = !(currencyCode && composerTiles);
    setLoading(isLoading);
    if (!isLoading) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    if (
      !(queryParams.has('stripeSuccess') || queryParams.has('stripeFailed'))
    ) {
      setNamedLocalStorage('stripeSessionId', '');
    }
    const responses = await Promise.all([getAllComposerTiles(), getShop()]);
    if (responses.every((r: any) => r.status < 400 && r.data)) {
      const [composer, shop] = responses;
      const { data: composerData } = composer;
      const { data: shopData } = shop;
      const {
        imageUrl: image,
        currency,
        languages,
        config: configData,
        table: customerTable,
        profile,
        defaultPaymentProvider
      } = shopData;

      // eslint-disable-next-line prefer-destructuring
      const defaultLanguage = (
        languages?.find((lang: Language) => lang.defaultLanguage)
          ?.languageCode || DEFAULT_LOCALE
      ).split('-')[1];

      const gridItems: GridItemProps[] = mapGridItems(composerData.tiles);
      setRootComposerGroup(composerData.rootComposerGroup);
      setComposerTiles(gridItems);
      setCurrencyCode(currency ? currency.currencyCode : '');
      setConfig(configData);
      setVisualProfile(profile?.color);
      setVisualProfileFonts(profile?.font);
      setDefaultLocale(defaultLanguage);
      setTable(customerTable);
      setImageUrl(
        image ||
          'https://k3imagine.blob.core.windows.net/selfserv/K3imagine-logo-dark.png'
      );
      setFetching(false);
      setShopName(customerTable.name);

      i18n.changeLanguage(defaultLocale);

      if (defaultPaymentProvider === 'Valitor') {
        const valitorMerchantData = await getValitorConfig();
        setPaymentProvider({
          type: defaultPaymentProvider,
          information: valitorMerchantData.data
        });
      } else if (defaultPaymentProvider === 'GlobalPay') {
        const obPayMerchantData = await getGlobalPayConfig();
        setPaymentProvider({
          type: defaultPaymentProvider,
          information: obPayMerchantData.data
        });
      } else {
        setPaymentProvider({ type: defaultPaymentProvider });
      }
    } else {
      setError('Failure in fetching app resources.');
    }
  };

  useEffect(() => {
    if (tenantId && shopId) {
      getComposerTiles();
    } else {
      setError('Top ID invalid (tenandId, shopId and/or tableId.');
    }
  }, [isFetching]);

  return {
    error,
    shopName,
    shopId,
    loading,
    tenantId,
    imageUrl,
    currencyCode,
    composerTiles,
    rootComposerGroup,
    config,
    table,
    visualProfileColors,
    visualProfileFonts,
    paymentProvider,
    defaultLocale,
    getComposerTiles
  };
};

export default useGlobalState;
