import { AxiosResponse, CancelToken } from 'axios';
import {
  AdyenSessionConfig,
  AdyenSessionResponse,
  BasketItem,
  PaymentBreakdown,
  PostSalePayload,
  SaleItem,
  SaleResponse,
  ValitorMerchant,
  ValitorSignatureResponse
} from '../types';
import api from '../utils/api';

const mapBasketItemsToSalesItems = (basketItem: BasketItem[]): SaleItem[] => {
  return basketItem.map(i => {
    let bundleSelections;
    let modifierSelections;
    if (i.customization?.modifierSelections) {
      modifierSelections = i.customization?.modifierSelections.map(m => ({
        modifierId: m.referenceId,
        quantity: m.quantity
      }));
    }
    if (i.customization?.bundleSelections) {
      bundleSelections = i.customization?.bundleSelections.map(m => ({
        referenceId: m.referenceId,
        bundleGroupId: m.bundleGroupId
      }));
    }

    return {
      localStorageBasketItemIdentifier: i.id,
      referenceId: i.referenceId,
      type: i.type,
      quantity: i.quantity,
      orderDetail: i.customization?.orderDetail,
      modifierSelections,
      bundleSelections
    };
  });
};

export const getPaymentBreakdown = async (
  basketItems: BasketItem[],
  basketId: string
): Promise<AxiosResponse<PaymentBreakdown>> => {
  const salesItems = mapBasketItemsToSalesItems(basketItems);
  return api.post(`/payment/breakdown/${basketId}`, salesItems);
};

export const getAdyenSessionConfig = async (
  basketId: string
): Promise<AxiosResponse<AdyenSessionConfig>> =>
  api.get(`paymentprovider/adyen/${basketId}/session`);

export const startAdyenSession = async (
  basketId: string,
  email?: string,
  cancelToken?: CancelToken
): Promise<AxiosResponse<{ error: string } | AdyenSessionResponse>> =>
  api.post(
    `paymentprovider/adyen/${basketId}/session`,
    { email },
    { cancelToken }
  );

export const getValitorConfig = async (): Promise<AxiosResponse<
  ValitorMerchant
>> => {
  return api.get('paymentprovider/valitor/merchant');
};

export const getValitorSignature = async (
  amount: number
): Promise<AxiosResponse<ValitorSignatureResponse>> => {
  return api.get('paymentprovider/valitor/signature', { params: { amount } });
};

export const getGlobalPayConfig = async (): Promise<AxiosResponse<any>> => {
  return api.get('paymentprovider/globalpay/merchant');
};

export const getGlobalPayHpp = async (
  requestData: any
): Promise<AxiosResponse<any>> => {
  return api.post('paymentprovider/globalpay/generatehpp', requestData);
};

export const getCountryCodes = async (
  requestData: any
): Promise<AxiosResponse<any>> => {
  return api.post('ISO/countrycodes', requestData);
};

export const postSale = async (
  email: string | undefined,
  basketItems: BasketItem[],
  basketId: string,
  paymentResponse?: PostSalePayload
): Promise<AxiosResponse<SaleResponse>> => {
  const data = {
    basketId,
    email,
    saleItems: mapBasketItemsToSalesItems(basketItems),
    ...(paymentResponse || {})
  };
  return api.post('sales', data);
};
