import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { Shop } from '../types/shop.type';

export const getShop = async (): Promise<AxiosResponse<Shop>> => {
  try {
    return await api.get('/Shop');
  } catch (e) {
    return e.response;
  }
};
