import { urlParams } from './urlUtils';

const getName = (name: string) => {
  return `${urlParams.tenantId || ''}-${urlParams.shopId || ''}.${name}`;
};
export const getNamedLocalStorage = (name: string): string | null => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return localStorage.getItem(getName(name));
  }
  return null;
};

export const setNamedLocalStorage = (name: string, updatedResource: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.setItem(getName(name), updatedResource);
  }
};

export const removeNamedLocalStorage = (name: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.removeItem(getName(name));
  }
};
