import axios, { AxiosResponse, AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { urlParams } from './urlUtils';

const api = axios.create({
  baseURL: `${process.env.API_URL}/api/${process.env.API_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
    TenantId: urlParams.tenantId,
    ShopId: urlParams.shopId,
    TableId: urlParams.tableId
  }
});

api.interceptors.request.use(async options => {
  const newOptions = options;
  newOptions.headers['Request-Id'] = uuidv4();

  return newOptions;
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    return error.response;
  }
);

/* Add a response/request interceptor that counts the requests outstanding, this is to ensure
  that basket transactions etc are completed before the end sale transaction is started, so that the totals match
*/
let numberOfApiCallsPending = 0;

api.interceptors.request.use(
  function(config) {
    numberOfApiCallsPending += 1;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    numberOfApiCallsPending -= 1;
    return response;
  },
  function(error) {
    numberOfApiCallsPending -= 1;
    return Promise.reject(error);
  }
);

const outstandingAPICalls = () => {
  return numberOfApiCallsPending !== 0;
};

export default api;
export { outstandingAPICalls };
