import { ComponentProps } from 'react';
import { GridItem } from '@k3imagine/self-serve-components';
import exp from 'constants';

export type GridItemProps = ComponentProps<typeof GridItem>;
export type GridItemType = ComponentProps<typeof GridItem>['type'];

export type UpdateBasketItemInfo = {
  actionType: NumberPickerActionType;
  item: GridItemProps;
};

export type NumberPickerActionType = '+' | '-';

export type OrderCustomization = {
  orderDetail?: string;
  bundleSelections?: BundleSelection[];
  modifierSelections?: ModifierSelection[];
  additionalPriceBreakdown?: {
    modifierSelectionPrice?: number;
    bundleSelectionPrice?: number;
  };
};

export type BasketItem = {
  id: number;
  referenceId: number;
  imageUrl?: string;
  price?: number;
  label?: string;
  quantity: number;
  customization?: OrderCustomization;
  type: GridItemType;
  salesPrice?: number;
};

export type BasketType = {
  externalUid: string;
  totalPrice: number;
  totalQuantity: number;
  basketItems: BasketItem[];
  completedTime?: string;
};

export type BundleSelection = {
  referenceId: number;
  bundleGroupId: number;
  label?: string;
  imageUrl?: string;
};

export type ModifierSelection = {
  modifierId: number;
  referenceId: number;
  label?: string;
  quantity: number;
  defaultValue?: number;
  imageUrl?: string;
  modifierGroupId?: number;
  salesPrice: number;
};

export enum Comments {
  LABEL = 'Comments'
}
