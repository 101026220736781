import { Url } from 'url';
import {
  DigitalWalletResponseInfo,
  StripePaymentResponseInfo,
  ValitorPaymentResponseInfo
} from './payment.type';

export type PaymentProviderType =
  | 'Adyen'
  | 'GlobalPay'
  | 'PaymentExtension'
  | 'Valitor'
  | 'Stripe';

export type PaymentProvider = {
  type: PaymentProviderType;
  information?: ValitorInformation & OBPayInformation;
};

export enum AdyenPaymentResultCode {
  Authorised = 'Authorised',
  Error = 'Error',
  Pending = 'Pending',
  PresentToShopper = 'PresentToShopper',
  Refused = 'Refused',
  Received = 'Received'
}

export interface AdyenSessionConfig {
  id: string;
  clientKey: string;
  environment: string;

  [key: string]: string;
}

export interface AdyenSessionResponse {
  id: string;
  environment: string;
  clientKey: string;
  session: any; // this is a field that's passed through to Adyen drop-in as is
  paymentMethodsConfig: Record<string, any>;
}

export type ValitorInformation = {
  key?: string;
  name?: string;
  url?: string;
};

export type OBPayInformation = {
  merchantId?: string;
  useProductionEnvironment?: boolean;
  enableApplePay?: boolean;
  AppleDvcFilename?: string;
  enableGooglePay?: boolean;
  googlePayMerchantId?: string;
  merchantCountryCode?: string;
};

export type PaymentExtensionInformation = {
  type: 'PaymentExtension';
  information?: {
    name: string;
    url: string;
    config: Object;
  };
};

export type ValitorSignatureResponse = {
  merchantReferenceId: string;
  signature: string;
};

export type PostSalePayload =
  | { adyenResponse: any }
  | { globalPayResponse: any; digitalWallet?: DigitalWalletResponseInfo }
  | { valitorPayResponse: ValitorPaymentResponseInfo }
  | { stripePaymentResponse: any };

export type StripeBasketStatus = {
  basketUid?: string;
  sessionId?: string;
  status?: string;
};

export type StripeCheckoutResult = {
  sessionId?: string;
  stripePageUrl?: string;
};
