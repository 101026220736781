import { AxiosResponse } from 'axios';
import api from '../utils/api';
import { ComposerType } from '../types/composer-group.type';

export const getAllComposerTiles = async (): Promise<AxiosResponse<
  ComposerType
>> => {
  try {
    const params = new URLSearchParams();
    params.append('currentTime', new Date().toTimeString().split(' ')[0]);
    return await api.get('/Composer/tiles', { params });
  } catch (e) {
    return e.response;
  }
};
